import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Layout } from 'antd'
import { SecurityVerificationPc as SecurityVerification } from '@/components/SecurityVerification/index.pc'
import ContentWrapper from '@pc/components/ContentWrapper'
import MegaDiscountList from './components/MegaDiscountList'
import { PaymentProcessPc } from './components/PaymentProcess'
import LoginRegisterPanel from './components/LoginRegisterPanel'
import Logger from '../../config/logger'
import { actionCreator } from './store'
import { actionCreator as registerActionCreator } from '../register/store'
import { globalActionCreator } from '@pc/common/globalActions'
import { helpers, withBasic } from '@common'
import BizTracker from '@pc/common/tracker/bizTracker'
import { getCommonParams } from '@/components/Security/utils'
import {
  FIRST_PAGE_TYPE,
  PHONE_COUNTRY_CODE_MAP,
  SECURITY_VERIFICATION_BIZ_TYPE,
  PC_NOW_OPERATION_TYPE,
  SCENARIO_NUMBER_MAP,
  SLIDER_CONFIG,
  FACE_CONFIG,
} from '@pc/common/macro'
import { store } from '../../store'

import loggerConfig from './logger.config'
import { getApiBaseUrl, phoneDesensitization } from '@pc/config/utils'

import styles from './style.m.scss'
import withPerfCollect from '@/components/withPerfCollect'
import { getSmDeviceData, getDeviceUUID } from '@/components/device-sdk'

const { getRuntimeEnv } = helpers
const className = helpers.classNames.react(styles)
const { Content } = Layout

@withBasic
class LoginPc extends PureComponent {
  constructor(props) {
    super(props)

    this.saLogger = Logger.getInstance()
    this.state = {
      deviceUuid: helpers.storage.getSession('deviceData')?.uuid,
    }
  }

  componentDidMount() {
    const {
      appId,
      refNo,
      sign,
      // getOrderInfo,
      // token,
      getBannerConfig,
      // defaultPeriod,
      clearOrderDetailData,
      uploadDeviceData,
    } = this.props

    getBannerConfig(appId, refNo, sign)
    clearOrderDetailData()
    uploadDeviceData()
    this.getAndReportConfig()
    BizTracker.enter({ sn: 390001 })
    // getOrderInfo(appId, refNo, sign, defaultPeriod)
    /* //携带token代表可能为BL的绑定用户
    token
      ? checkIfFromBL(token, appId, refNo, sign)
      : getOrderInfo(appId, refNo, sign, defaultPeriod) */
    if (!this.state.deviceUuid) {
      getDeviceUUID().then((uuid) => {
        this.setState({ deviceUuid: uuid })
      })
    }
  }

  getAndReportConfig = async () => {
    const { data } = await this.props.dispatch(
      globalActionCreator.globalGetReportConfig(),
    )
    this.saLogger.onPageView({
      ...loggerConfig.page_view,
      refNo: this.props.refNo,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      partnerUserId: data?.partnerUserId,
      Aku_scene: data?.akuScene ? 1 : 2,
      appName: data?.appName,
    })
  }

  // 获取当前页面模块的banner图
  getCurrentBanner = () => {
    const { bannerList } = this.props

    // pc 展示只处理登录页
    const targetBanner =
      bannerList &&
      bannerList.find((item) => item.location === FIRST_PAGE_TYPE.LOGIN)

    return targetBanner ? targetBanner : null
  }

  // 判断是否要展示图片
  checkBanner = () => {
    return this.getCurrentBanner()
  }

  // 获取图片
  getImg = () => {
    const currentBanner = this.getCurrentBanner()
    return currentBanner ? currentBanner.imagePc : null
  }

  // 点击图片跳转
  handleBannerJump = () => {
    const currentBanner = this.getCurrentBanner()
    if (currentBanner && currentBanner.jumpUrl) {
      this.saLogger.onPageClick({
        ...loggerConfig.click_banner,
        Aku_links: currentBanner.jumpUrl,
      })
      // 使用定时器 是为了让埋点能够正常发出去
      setTimeout(() => {
        window.location.href = currentBanner.jumpUrl
      }, 300)
    }
  }

  componentWillUnmount() {
    BizTracker.leave()
  }

  render() {
    const { translate, countryId, languageCode } = this.props

    const {
      securityVerificationVisible,
      validPhoneNumber,
      defaultPhoneNumber,
      nowOperationType,
      securityVerificationOnClose,
      securityVerificationOnSuccess,
      securityVerificationPhoneNumber,
      securityVerificationOnReject,
    } = this.props

    return (
      <ContentWrapper {...className('pc-container')}>
        <Content {...className('pc-container-content')}>
          <div {...className('up-content')}>
            {this.checkBanner() ? (
              <div {...className('activity-image')}>
                <a onClick={this.handleBannerJump}>
                  <img src={this.getImg()} alt="" />
                </a>
              </div>
            ) : null}
            <div {...className('up-content-title')}>
              {translate('支付流程')}
            </div>
            <div {...className('payment-process-pic')}>
              <PaymentProcessPc />
            </div>
          </div>
          <div {...className('down-content')}>
            <div {...className('down-content-title font-lato-medium')}>
              {translate('特大优惠')}
            </div>
            <MegaDiscountList type="pc" />
            <div {...className('login-register-area')}>
              <LoginRegisterPanel />
            </div>
          </div>
          {securityVerificationVisible ? (
            <SecurityVerification
              deviceId={this.props.device_id}
              deviceUuid={this.state.deviceUuid}
              visible={securityVerificationVisible}
              locale={languageCode ? languageCode.toLowerCase() : 'in'}
              languageCode={languageCode ? languageCode.toLowerCase() : 'in'}
              bizType={
                nowOperationType === PC_NOW_OPERATION_TYPE.LOGIN
                  ? SECURITY_VERIFICATION_BIZ_TYPE.PC_LOGIN
                  : SECURITY_VERIFICATION_BIZ_TYPE.REGISTER
              }
              env={getRuntimeEnv()}
              countryCode={`+${PHONE_COUNTRY_CODE_MAP[countryId]}`}
              saParams={{
                pageId: 'openpay01',
                pageName: 'opaypay-login page',
              }}
              phoneNumber={phoneDesensitization(
                validPhoneNumber ||
                  defaultPhoneNumber ||
                  securityVerificationPhoneNumber,
              )}
              extraParams={{
                phone:
                  validPhoneNumber ||
                  defaultPhoneNumber ||
                  securityVerificationPhoneNumber,
                platformId: helpers.URL.getParam('appId'),
                partnerPhone:
                  validPhoneNumber ||
                  defaultPhoneNumber ||
                  securityVerificationPhoneNumber,
                ...getCommonParams(),
              }}
              onSuccess={securityVerificationOnSuccess}
              onRejected={securityVerificationOnReject}
              onClose={securityVerificationOnClose}
              // onRequestError={securityVerificationOnClose}
              apiBaseURL={getApiBaseUrl('/capi')}
              getSmDeviceData={getSmDeviceData}
              sliderData={SLIDER_CONFIG}
              faceOptions={FACE_CONFIG}
            />
          ) : (
            ''
          )}
        </Content>
      </ContentWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  appId: state.getIn(['main', 'appId']),
  refNo: state.getIn(['main', 'refNo']),
  sign: state.getIn(['main', 'sign']),
  defaultPeriod: state.getIn(['main', 'defaultPeriod']),
  fromLazada: state.getIn(['main', 'fromLazada']),
  token: state.getIn(['main', 'token']),
  curPeriod: state.getIn(['login', 'curPeriod']),
  activity: state.getIn(['login', 'activity']),
  callbackPageUrl: state.getIn(['login', 'callbackPageUrl']),
  loginBanner: state.getIn(['login', 'loginBanner']),
  bannerList: state.getIn(['login', 'bannerList']),
  validPhoneNumber: state.getIn(['login', 'validPhoneNumber']),
  defaultPhoneNumber: state.getIn(['login', 'defaultPhoneNumber']),
  loginType: state.getIn(['login', 'loginType']),
  securityVerificationVisible: state.getIn([
    'login',
    'securityVerificationVisible',
  ]),
  securityVerificationPhoneNumber: state.getIn([
    'login',
    'securityVerificationTempParams',
    'phoneNumber',
  ]),
  nowOperationType: state.getIn(['login', 'pcData', 'nowOperationType']),
  device_id: helpers.storage.get('deviceId') || 'unknown',
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  uploadDeviceData() {
    dispatch(
      globalActionCreator.globalSendDeviceData({
        scenario_number: SCENARIO_NUMBER_MAP.LOGIN_PAGE,
      }),
    )
  },
  // getOrderInfo(appId, refNo, sign, defaultPeriod) {
  //   dispatch(actionCreator.goGetOrderInfo(appId, refNo, sign, defaultPeriod))
  // },
  checkIfFromBL(token) {
    dispatch(actionCreator.goCheckIfFromBL(token))
  },
  clearOrderDetailData() {
    dispatch(globalActionCreator.globalClearOrderDetailData())
  },
  getBannerConfig(appId, refNo, sign) {
    dispatch(actionCreator.getBannerConfig(appId, refNo, sign))
  },
  // 安全组件关闭
  securityVerificationOnClose() {
    dispatch(actionCreator.toggleSecurityVerification(false))
  },
  // 安全组件校验通过
  securityVerificationOnSuccess({ operationId }) {
    const state = store.getState()
    const saLogger = Logger.getInstance()
    const nowOperationType = state.getIn([
      'login',
      'pcData',
      'nowOperationType',
    ])
    // 关闭安全校验弹窗
    dispatch(actionCreator.toggleSecurityVerification(false))

    // 登录
    if (nowOperationType === PC_NOW_OPERATION_TYPE.LOGIN) {
      saLogger.click({
        ...loggerConfig.login_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.LOGIN,
        return_code: 1,
      })
      dispatch(actionCreator.setOperationId(operationId))
      const securityVerificationTempParams = state
        .getIn(['login', 'securityVerificationTempParams'])
        .toJS()
      const params = {
        ...securityVerificationTempParams,
        securityCheck: true,
      }
      dispatch(actionCreator.goLoginWithPwd(params))
    }

    // 注册
    if (nowOperationType === PC_NOW_OPERATION_TYPE.REGISTER) {
      saLogger.click({
        ...loggerConfig.otp_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.REGISTER,
        return_code: 1,
      })
      dispatch(actionCreator.setOperationId(operationId))
      const params = state
        .getIn(['login', 'securityVerificationTempParams'])
        .toJS()
      const { phoneNumber, imageCaptcha } = params
      dispatch(registerActionCreator.savePhoneNumber(phoneNumber))
      dispatch(
        registerActionCreator.goSendCode(phoneNumber, imageCaptcha, true),
      )
    }
  },
  securityVerificationOnReject() {
    const state = store.getState()
    const saLogger = Logger.getInstance()
    const nowOperationType = state.getIn([
      'login',
      'pcData',
      'nowOperationType',
    ])
    if (nowOperationType === PC_NOW_OPERATION_TYPE.LOGIN) {
      saLogger.click({
        ...loggerConfig.login_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.LOGIN,
        return_code: 2,
      })
    } else if (nowOperationType === PC_NOW_OPERATION_TYPE.REGISTER) {
      saLogger.click({
        ...loggerConfig.otp_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.REGISTER,
        return_code: 2,
      })
    }
  },
})

export default withPerfCollect()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPc)),
)
