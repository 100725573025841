import React, { PureComponent, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { actionCreator as LoginActionCreator } from '../../../login/store'
import BizTracker from '@pc/common/tracker/bizTracker'
import { helpers, withBasic, asyncComponent } from '@common'
import { actionCreator } from '../../store'
import md5 from 'md5'

const GraphicCodeFormPC = asyncComponent(() =>
  import('../GraphicCodeForm/index.pc'),
)

import {
  PhoneInput,
  CodeInput,
  PasswordInput,
} from '../../../login/components/InputFields'
import { Button } from '@pc/components/Button'
import Logger, * as elementId from '@pc/config/logger'
import loggerConfig from '../../../login/logger.config'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class RegisterFormPc extends PureComponent {
  constructor() {
    super()
    this.state = {
      password: '',
      confirmPassword: '',
      smsCode: '', //短信验证码
      phoneNumber: '',
      isPwdLegal: false, //密码是否合法
      isConfirmPwdLegal: false, //确认密码是否合法
    }

    this.saLogger = Logger.getInstance()
    this.controls = []
  }

  handlePasswordInput = (name, status) => {
    if (name === 'password') {
      this.setState({
        isPwdLegal: status,
      })
    } else {
      this.setState({
        isConfirmPwdLegal: status,
      })
    }
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  //如果控件被销毁，则要重置数组
  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  checkControlList = (filterObj) => {
    let result = {
      isValid: true,
    }
    const includeList = filterObj.includeList
    const excludeList = filterObj.excludeList

    for (let i = 0; i < this.controls.length; i++) {
      const elem = this.controls[i]
      //待校验组件在exludeslist中，则跳过校验
      if (excludeList && excludeList.includes(elem.name)) continue

      //待校验组件不在includelist中，则也跳过校验
      //若同一个组件同时出现在两个list中，则excludeList优先级更高
      if (includeList && !includeList.includes(elem.name)) continue
      //如果校验通过
      if (elem.validate(elem.state.value) === false) continue

      // 只要有一个不通过，则跳出循环，返回错误信息
      const errMsg = elem.validate(elem.state.value)
      result = {
        isValid: false,
        [elem.name]: errMsg,
      }
      break
    }

    return result
  }

  /*
   * filterObj {obj}: 校验过滤
   */
  beforeSubmit = (filterObj) => {
    let result = this.checkControlList(filterObj)

    if (result.isValid) {
      const { password, confirmPassword } = this.state
      const { setPasswordErrorText, translate } = this.props

      if (password !== confirmPassword) {
        setPasswordErrorText(translate('两次输入的密码不同，请重新输入！'))
        result.isValid = false
      }
    }
    return result
  }

  sendCode = () => {
    const { sendCodeWithPhoneNumber } = this.props
    const { phoneNumber } = this.state

    const result = this.beforeSubmit({ excludeList: ['smsCode'] })

    if (result.isValid) {
      sendCodeWithPhoneNumber(phoneNumber)
      this.saLogger.onPageClick({
        ...loggerConfig.send,
        Aku_channelName: 'sms',
      })
    }
  }

  /**
   *
   * @param {boolean} securityCheck 是否经过安全校验
   */
  submit = (securityChecked = false) => {
    BizTracker.click({
      cn: 12,
      sn: 390001,
    })
    const {
      userRegister,
      toggleSecurityVerification,
      setSecurityVerificationTempParams,
    } = this.props
    const { phoneNumber, smsCode, password } = this.state

    const result = this.beforeSubmit({})
    if (result.isValid) {
      // 改成在获取注册短信码的时候触发安全校验，提交的时候无需再次触发了，这里暂时注释掉
      // if (!securityChecked) {
      //   toggleSecurityVerification(true)
      //   setSecurityVerificationTempParams({
      //     phoneNumber,
      //     smsCode,
      //     password: md5(password)
      //   })
      // } else {
      userRegister(phoneNumber, smsCode, md5(password))
      // }
    }
  }

  render() {
    const {
      pwdErrorText,
      codeErrorText,
      countryCode,
      sent,
      countDown,
      openAgreement,
      languageCode,
      setValidPhoneNumber,
    } = this.props
    const { setCodeErrorText, translate, setPasswordErrorText } = this.props

    return [
      <form key="register" {...className('form-register-pc')}>
        <p {...className('form-title font-lato-reg')}>{translate('手机号')}</p>
        <PhoneInput
          name="phoneNumber"
          type="text"
          placeholder={translate('手机号')}
          onRegister={this.register}
          onControl={this.collect}
          validation={{ type: 'phone' }}
          platform="pc"
          onSuccess={() => {
            // this.handlePhoneInput(true)
          }}
          onError={() => {
            // this.handlePhoneInput(false)
          }}
          onFocus={() => {
            this.saLogger.onPageClick({
              ...loggerConfig.click_phone_num,
              Aku_channelName: 'sms',
            })
          }}
          onBlur={(e, value) => {
            setValidPhoneNumber(value)
            this.saLogger.onPageClick({
              ...loggerConfig.input_phone_num,
              Aku_channelName: 'sms',
            })
          }}
          countryCode={countryCode}
          required
          hasTips
        />
        <p {...className('form-title font-lato-reg')}>{translate('密码')}</p>
        <PasswordInput
          name="password"
          type="password"
          placeholder={translate('请输入密码（8-16位字符）')}
          onRegister={this.register}
          onControl={this.collect}
          validation={{ type: 'password' }}
          platform="pc"
          onSuccess={() => {
            this.handlePasswordInput('password', true)
          }}
          onError={(errMsg) => {
            this.handlePasswordInput('password', false)
          }}
          onFocus={() => {
            this.saLogger.onPageClick(loggerConfig.click_set_password_box)
          }}
          onBlur={() => {
            this.saLogger.onPageInput(loggerConfig.input_set_password)
          }}
          hasTips
        />
        <PasswordInput
          name="confirmPassword"
          type="password"
          placeholder={translate('请再次输入密码')}
          onRegister={this.register}
          onControl={this.collect}
          errorText={pwdErrorText}
          platform="pc"
          onSuccess={() => {
            this.handlePasswordInput('confirmPassword', true)
          }}
          onError={(errMsg) => {
            this.handlePasswordInput('confirmPassword', false)
          }}
          onFocus={() => {
            setPasswordErrorText('')
            this.saLogger.onPageClick(loggerConfig.click_password_again_box)
          }}
          onBlur={() => {
            this.saLogger.onPageInput(loggerConfig.input_password_again)
          }}
        />
        <p {...className('form-title font-lato-reg')}>{translate('验证码')}</p>
        <CodeInput
          name="smsCode"
          type="text"
          placeholder={translate('验证码')}
          onRegister={this.register}
          onControl={this.collect}
          validation={{ type: 'code' }}
          onSend={() => this.sendCode()}
          btnDisable={!sent ? false : true}
          btnText={sent ? `${countDown}s` : translate('发送')}
          btnDisable={sent}
          errText={codeErrorText}
          platform="pc"
          onFocus={() => {
            setCodeErrorText('')
            this.saLogger.onPageClick({
              ...loggerConfig.click_password_box,
              Aku_channelName: 'sms',
            })
          }}
          onBlur={() => {
            this.saLogger.onPageClick({
              ...loggerConfig.input_password,
              Aku_channelName: 'sms',
            })
          }}
          onSuccess={() => {
            // this.handleCodeInput(true)
          }}
          onError={(errMsg) => {
            // this.handleCodeInput(false)
          }}
        />
        <p {...className('terms')}>
          <span>{translate('点击注册代表您已阅读并同意以下')}</span>
          <a
            onClick={() => {
              // openAgreement(countryCode, languageCode)
              this.saLogger.onPageClick({
                ...loggerConfig.click_agreement,
                Aku_channelName: 'sms',
              })
              location.href = 'https://www.akulaku.com/artikel/privacyprivasi/' // user_Agreement
            }}
          >
            {translate('条款协议')}
          </a>
        </p>
        <Button
          text={translate('注册')}
          onClick={this.submit}
          platform="pc"
          {...className('btn-register-pc')}
        />
      </form>,
      <form key="graphicForm">
        <GraphicCodeFormPC phoneNumber={this.state.phoneNumber} />
      </form>,
    ]
  }
}

const mapStateToProps = (state) => ({
  sent: state.getIn(['register', 'sent']),
  countDown: state.getIn(['register', 'countDown']),
  codeErrorText: state.getIn(['register', 'codeErrorText']),
  pwdErrorText: state.getIn(['register', 'pwdErrorText']),
  graphicUrl: state.getIn(['register', 'graphicUrl']),
  graphicErrorText: state.getIn(['register', 'graphicErrorText']),
  showGraphicDialog: state.getIn(['register', 'showGraphicDialog']),
})
const mapDispatchToProps = (dispatch) => ({
  setValidPhoneNumber(validPhoneNumber) {
    dispatch(LoginActionCreator.goSetCurValidPhoneNumber(validPhoneNumber))
  },
  sendCodeWithPhoneNumber(phoneNumber) {
    dispatch(actionCreator.goSendCode(phoneNumber))
  },
  submitSmsCode(code, phoneNumber) {
    // goSubmitSmsCode
    // 不存在，先注释以下代码
    // dispatch(actionCreator.goSubmitSmsCode(code, phoneNumber))
  },
  setPasswordErrorText(errText) {
    dispatch(actionCreator.goSetPasswordErrorText(errText))
  },
  setCodeErrorText(errText) {
    dispatch(actionCreator.goSetCodeErrorText(errText))
  },
  userRegister(phoneNumber, captcha, password) {
    dispatch(actionCreator.savePhoneNumber(phoneNumber))
    dispatch(
      actionCreator.goRegister(phoneNumber, captcha, true, password, password),
    )
  },
  openAgreement(countryCode, languageCode) {
    languageCode === 'EN'
      ? (window.location.href = `/v2/terms.html?lang=ph`)
      : (window.location.href = `/v2/terms.html?lang=${countryCode.toLowerCase()}`)
  },
  clearAllErrMsg() {
    dispatch(actionCreator.goSetGraphicErrorText(''))
    dispatch(actionCreator.goSetCodeErrorText(''))
    dispatch(actionCreator.goSetPasswordErrorText(''))
  },
  toggleSecurityVerification(show) {
    dispatch(actionCreator.toggleSecurityVerification(show))
  },
  setSecurityVerificationTempParams(params) {
    dispatch(actionCreator.setSecurityVerificationTempParams(params))
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterFormPc),
)
