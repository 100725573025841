import React, { PureComponent } from 'react'
import { helpers } from '@common'
import styles from './style.m.scss'

const className = helpers.classNames.react(styles)

export class ALTab extends PureComponent {
  constructor(props) {
    super()
    this.state = {
      activeValue: props.activeValue,
    }
  }

  onTabClick = (value) => {
    this.setState({
      activeValue: value,
    })
    this.props.onChoose(value)
  }

  render() {
    const { tabList, onChoose } = this.props
    const { activeValue } = this.props

    return (
      <div {...className('al-tab-container')}>
        {tabList.map((item, index) => {
          return (
            <label
              onClick={() => this.onTabClick(item.value)}
              {...className('al-tab-item')}
              active={(activeValue === item.value).toString()}
              key={item.value}
            >
              <input type="radio" checked={activeValue === item.value} />
              <span>{item.text}</span>
            </label>
          )
        })}
      </div>
    )
  }
}
