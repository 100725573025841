import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import BizTracker from '@pc/common/tracker/bizTracker'
import { helpers, withBasic } from '@common'
import { actionCreator } from '../../store'
import * as registerActionCreator from '../../../register/store/actionCreator'

import { ALTab } from '@pc/components/ALTab'
import LoginForm from '../LoginForm'
import RegisterFormPc from '../../../register/components/RegisterFormPc'

import { PC_NOW_OPERATION_TYPE } from '@pc/common/macro'
import Logger, * as elementId from '@pc/config/logger'
import loggerConfig from '../../logger.config'
import styles from './style.m.scss'

const className = helpers.classNames.react(styles)

@withBasic
class LoginRegisterPanel extends PureComponent {
  render() {
    const { translate, switchLoginRegister, nowOperationType } = this.props

    return (
      <div {...className('login-register-panel')}>
        <div {...className('panel-title')}>{translate('登录Akulaku')}</div>
        <ALTab
          activeValue={nowOperationType}
          orm-title
          tabList={[
            {
              text: translate('登录'),
              value: PC_NOW_OPERATION_TYPE.LOGIN,
            },
            {
              text: translate('注册'),
              value: PC_NOW_OPERATION_TYPE.REGISTER,
            },
          ]}
          onChoose={(val) => {
            switchLoginRegister(val, val !== nowOperationType)
          }}
        />
        <div {...className('form-wrapper')}>
          {nowOperationType === PC_NOW_OPERATION_TYPE.LOGIN ? (
            <LoginForm type="pc" />
          ) : (
            <RegisterFormPc />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  nowOperationType: state.getIn(['login', 'pcData', 'nowOperationType']),
  curPeriod: state.getIn(['login', 'curPeriod']),
  callBackPageUrl: state.getIn(['login', 'callBackPageUrl']),
})

const mapDispatchToProps = (dispatch) => ({
  switchLoginRegister(value, isSwitch) {
    if (value === 'login') {
      // pc点击密码登陆切换标签
      BizTracker.click({ cn: 8, sn: 390001 })
    }
    if (isSwitch) {
      // 切换了面板
      if (value === 'register') {
        // 进入注册面板
        BizTracker.enter({ sn: 390002 })
      } else {
        // 离开注册面板
        BizTracker.leave({ sn: 390002 })
      }
    }
    const saLogger = Logger.getInstance()
    saLogger.onPageClick({
      ...loggerConfig.switch_login_method,
      Aku_channelName:
        value === PC_NOW_OPERATION_TYPE.LOGIN ? 'account' : 'sms',
      Aku_buttonName:
        value === PC_NOW_OPERATION_TYPE.LOGIN
          ? 'account login'
          : 'phone number login',
    })
    dispatch(actionCreator.goSetNowOperationType(value))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginRegisterPanel)
